module.exports = {
    list: [
        // template inventories report
        "INSTANT_INVENTORY",
        // template last search report
        "LAST_SEARCHES",
        // template custom fields histories report
        "CUSTOMFIELDS_HISTORY",
        // template geofences histories report
        "GEOFENCES_HISTORY",
        // template geo notification histories report
        "GEONOTIFICATIONS_HISTORY",
        // template asset association / disassociation
        "ASSET_ASSOCIATION_INVENTORY",
        // template history of the last known position before asset out of coverage
        "LAST_KNOWN_POSITION"
    ]
};
